import React from "react";
//import  ReactDOM  from "react-dom";

 
function Units (){
    
    return(
<div className="images-page">
<h1>This is Units page !</h1>
   
</div>
    );
}


export default Units;