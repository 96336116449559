import React from "react";
import "./product.css";
 
function Location (){
    
    return(
<div className="images-page">
<h1>This is Location page !</h1>
   
</div>
    );
}


export default Location;