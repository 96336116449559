import React from "react";


 
function Managment (){
    
    return(
<div className="images-page">
<h1>This is Managment page !</h1>

   
</div>
    );
}


export default Managment;