import React from "react";

import "./product.css";
 
function Product(){
    return(
<div >
    <h1>This is product page !</h1>
    <img className="images" alt="xyz" src="https://www.bel-india.in/writereaddata/TST%20pictu20180523121329494.JPG"/>
   
</div>
    );
}

export default Product;