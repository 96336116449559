import React from "react";

 
function WhatWeDo (){
    
    return(
<div>
<h1>This is WhatWeDo page !</h1>
   
</div>
    );
}


export default WhatWeDo;