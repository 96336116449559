import React from "react";
import Body from "./components/body";
import Footer from "./components/footer";
import Header from "./components/header";

 
function Home (){
    return(
<div>
    <Header/>
   <Body/>
    <Footer/>
</div>
    );
}


export default Home;