import React from "react";

 
function AboutUs (){
    
    return(
<div>
<h1>This is AboutUs page !</h1>
   
</div>
    );
}


export default AboutUs;