import React from "react";

 
function Careers (){
    
    return(
<div>
<h1>This is Careers page !</h1>
   
</div>
    );
}


export default Careers;