import Delhi from "../images/delhi.jpg";
import Drdo from "../images/drdo.jpg";
import Missile from "../images/missile.jpg";
import Radar from "../images/radar.jpg";
import Van from "../images/van.jpg";
import Ship from "../images/ship.jpg";
import Three from "../images/1632816881903.jpg";
import Two from "../images/1632816881943.jpg";
import One from "../images/1632816881911.jpg";
import Modi from "../images/modi.jpg";
import Yoga from "../images/yoga.jpg";
import Kannada from "../images/Kannada.jpg";
import Dopler from "../images/dopler.jpg";
import Office from "../images/office.jpg";





export const images = [
    { img:Kannada  },
    { img:Yoga  },
    { img:Modi  },
    { img:Delhi  },
    { img:Drdo  },
    { img:Missile },
    { img:Ship },
    { img:Van },
    { img:Dopler },
    { img:Three },
    { img:One },
    { img:Two },
    { img:Office },
    { img:Radar}
]




