import React from "react";

 
function Vegilence (){
    
    return(
<div>
<h1>This is Vegilence page !</h1>
   
</div>
    );
}


export default Vegilence;