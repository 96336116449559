import React from "react";

 
function MakeinIndia (){
    
    return(
<div>
<h1>This is MakeinIndia page !</h1>
   
</div>
    );
}


export default MakeinIndia;