import React from "react";
//import  ReactDOM  from "react-dom";
import Home from "./home";
 
function App(){
    return(
<div>
    <Home/>
</div>
    );
}


export default App;