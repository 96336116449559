import React from "react";

 
function Socialmedia (){
    
    return(
<div className="images-page">
<h1>This is Social Media page !</h1>
   
</div>
    );
}


export default Socialmedia;