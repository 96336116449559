import React from "react";

 
function ContactUs (){
    
    return(
<div>
<h1>This is ContactUs page !</h1>
<a href="https://www.bel-india.in/ContentPage.aspx?MId=30&CId=0&LId=1&link=0"> Contact to BEL </a>

   
</div>
    );
}


export default ContactUs;